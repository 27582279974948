"use client";

import dynamic from "next/dynamic";
import { useEffect, useRef, useState } from "react";

import styles from "./AccreditionsRecognitions.module.scss";

const QSRanking = dynamic(() => import("@/SvgIcons/QSRanking"), {
  ssr: false,
});
const WASC = dynamic(() => import("@/SvgIcons/WASC"), { ssr: false });

const WorldUniversityRanking = dynamic(() => import("@/SvgIcons/WorldUniversityRanking"), {
  ssr: false,
});
const AICTE = dynamic(() => import("@/SvgIcons/AICTE"), { ssr: false });
const DigitalEducationCouncil = dynamic(() => import("@/SvgIcons/DigitalEducationCouncil"), {
  ssr: false,
});
const WES = dynamic(() => import("@/SvgIcons/WES"), { ssr: false });

const NCHE = dynamic(() => import("@/SvgIcons/NCHE"), { ssr: false });
const UGC = dynamic(() => import("@/SvgIcons/UGC"), { ssr: false });

const SVG_List = [
  QSRanking,
  UGC,
  WASC,
  WES,
  AICTE,
  DigitalEducationCouncil,
  WorldUniversityRanking,
  NCHE,
];

export default function ClientWrapperIntersectionObserver ({ index }) {

  const SVGLIST  =  ({ index }) => {

    const Comp = SVG_List[index]
  
    return <Comp />; 
  }
  

  const ref = useRef(null);

  const [isIntersecting, setIntersecting] = useState(false)
  
  useEffect(() => {

  const observer = new IntersectionObserver(
    ([entry]) => entry.isIntersecting ? setIntersecting(entry.isIntersecting) : null
  )
    observer.observe(ref.current)
    return () => {
      observer.disconnect()
    }
  }, []);

  return  <div className={styles.svgContainer} ref={ref}>{isIntersecting && <SVGLIST index={index} />}</div>  ;
}
